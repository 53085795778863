.navbar {
  ul {
    margin: $space*3.6 $space 0 0;
    text-align: right;

    @include bp(xs) {
      margin-top: $space*5;
    }
  }

  li {
    display: block;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: $space;
    margin-left: $space;
    //text-transform: uppercase;

    @include bp(xs) {
      display: inline-block;
    }
  }

  a {
    color: $color-text;
    text-decoration: none;

    @include bp(lg) {
      font-size: $size*1.3;
    }

    &.active {
      color: $color-red-dark;
      text-decoration: underline;

      &:focus, 
      &:hover {
        color: $color-red-dark;
        cursor: text;
      }
    }

    &:focus, 
    &:hover {
      color: $color-green-text;
    }
  }
}