body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.container {
  margin: 0 auto;
  max-width: 63em;
  text-align: left; }
  @media (min-width: 30em) {
    .container {
      padding: 0 1rem; } }

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%; }
  .flex--around {
    -webkit-justify-content: space-around;
            justify-content: space-around; }
  .flex--center {
    -webkit-justify-content: center;
            justify-content: center; }
  .flex--column {
    -webkit-flex-direction: column;
            flex-direction: column; }
  .flex--padding li, .flex--padding p {
    padding-left: 1rem;
    padding-right: 1rem; }

.header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6; }
  .header:before {
    background: #ffffff;
    content: '';
    height: 100%;
    opacity: 0.75;
    position: absolute;
    width: 100%;
    z-index: -1; }

.hero {
  padding-top: 8rem; }
  .view--about .hero,
  .view--contact .hero,
  .view--events .hero {
    padding-bottom: 1.5rem; }
  @media (min-width: 30em) {
    .hero {
      padding-top: 6.6rem; } }
  @media (min-width: 62em) {
    .hero {
      padding-top: 7.2rem; } }

.main {
  min-height: 12rem;
  position: relative;
  z-index: 3; }

.section {
  padding: 1.5rem 1rem; }
  @media (min-width: 33.75em) {
    .section {
      padding: 2rem 1rem; } }

.wrapper {
  -webkit-align-items: center;
          align-items: center;
  min-width: 16em; }

body {
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif; }

a {
  color: #397E84; }
  a:focus, a:hover {
    color: #D73C3C; }

h1, h3, p {
  color: #0D0202; }

h1 {
  color: #A52222;
  font-size: 3rem;
  margin: 1rem 0; }

h2 {
  color: #397E84;
  font-size: 1.6rem;
  line-height: 1.2;
  margin: 0 0 1rem; }
  @media (min-width: 62em) {
    h2 {
      max-width: 74%; } }

h3 {
  color: #234B53;
  font-size: 1.1rem;
  line-height: 1.3; }
  @media (min-width: 62em) {
    h3 {
      font-size: 1.3rem; } }

.hidden {
  display: none; }

.hide {
  position: absolute;
  left: -9999em; }

.list {
  display: block;
  margin: 1rem 0 1rem; }
  .list .item {
    display: block;
    margin: 0 0 0.5rem; }

.offscreen {
  left: 9999rem;
  position: absolute; }

.w100 {
  width: 100%; }

.carousel.carousel-slider {
  min-height: 6rem; }

.carousel .carousel-status {
  display: none !important; }

.carousel .control-dots {
  bottom: 0.25rem !important;
  margin: 0 !important; }
  @media (min-width: 48em) {
    .carousel .control-dots {
      bottom: 1.5rem !important; } }
  .carousel .control-dots .dot {
    height: 0.66667rem !important;
    opacity: 0.5 !important;
    width: 0.66667rem !important; }
    .carousel .control-dots .dot:hover {
      background: #f64548 !important;
      opacity: 1 !important; }
    .carousel .control-dots .dot.selected {
      opacity: 1 !important; }
      .carousel .control-dots .dot.selected:hover {
        background: #ffffff !important; }

.carousel .slide {
  background: none !important;
  padding-bottom: 2rem; }
  .carousel .slide .legend {
    border-radius: 0 !important;
    bottom: 0 !important;
    font-size: 1rem !important;
    line-height: 1.1;
    margin-left: -50% !important;
    opacity: 1 !important;
    padding: 0.5rem 0.5rem 0.66667rem !important;
    width: 100% !important; }
    @media (min-width: 48em) {
      .carousel .slide .legend {
        bottom: 1rem !important;
        font-size: 1rem !important;
        padding: 1rem !important; } }
    .view--home .carousel .slide .legend {
      padding-bottom: 1.5rem !important; }
      @media (min-width: 48em) {
        .view--home .carousel .slide .legend {
          padding-bottom: 2rem !important; } }
    .carousel .slide .legend a {
      color: white; }
      .carousel .slide .legend a:focus, .carousel .slide .legend a:hover {
        color: #89cbcd; }

.carousel .thumbs-wrapper {
  border-bottom: 1px solid #234B53;
  margin: 0.66667rem 2px 1rem !important;
  padding-bottom: 0.5rem; }

.carousel .thumb.selected,
.carousel .thumb:hover {
  border-color: #f64548 !important; }

.view--about .control-dots,
.view--about .thumbs-wrapper,
.view--contact .control-dots,
.view--contact .thumbs-wrapper,
.view--events .control-dots,
.view--events .thumbs-wrapper {
  display: none !important; }

.footer-intro {
  margin-bottom: 9rem; }

.logo img {
  height: 8em;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
  width: auto; }
  @media (min-width: 30em) {
    .logo img {
      height: 9em; } }
  @media (min-width: 62em) {
    .logo img {
      height: 11em; } }

.logo-hover {
  display: block;
  opacity: 0;
  position: absolute;
  z-index: 6; }

.logo a:hover .logo-hover {
  opacity: 1; }

.navbar ul {
  margin: 3.6rem 1rem 0 0;
  text-align: right; }
  @media (min-width: 30em) {
    .navbar ul {
      margin-top: 5rem; } }

.navbar li {
  display: block;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  margin-left: 1rem; }
  @media (min-width: 30em) {
    .navbar li {
      display: inline-block; } }

.navbar a {
  color: #0D0202;
  text-decoration: none; }
  @media (min-width: 62em) {
    .navbar a {
      font-size: 1.3rem; } }
  .navbar a.active {
    color: #A52222;
    text-decoration: underline; }
    .navbar a.active:focus, .navbar a.active:hover {
      color: #A52222;
      cursor: text; }
  .navbar a:focus, .navbar a:hover {
    color: #397E84; }

