.container {
  margin: 0 auto;
  max-width: $content-max-width;
  text-align: left;

  @include bp(xs) {
    padding: 0 $space;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &--around {
    justify-content: space-around;
  }

  &--center {
    justify-content: center;
  }

  &--column {
    flex-direction: column;
  }

  &--padding {
    li, p {
      padding-left: $space;
      padding-right: $space;
    }
  }
}

.header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;

  &:before {
    background: $color-bg;
    content: '';
    height: 100%;
    opacity: 0.75;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}

.hero {
  padding-top: $space*8;

  .view--about &,
  .view--contact &,
  .view--events & {
    padding-bottom: $space*1.5;
  }

  @include bp(xs) {
    padding-top: 6.6rem;
  }

  @include bp(lg) {
    padding-top: 7.2rem;
  }
}

.main {
  min-height: $space*12;
  position: relative;
  z-index: 3;
}

.section {
  padding: $space*1.5 $space;

  @include bp(sm) {
    padding: $space*2 $space;
  }
}

.wrapper {
  align-items: center;
  min-width: $content-min-width;
}