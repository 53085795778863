// Color names from http://chir.ag/projects/name-that-color
$color-blue-dianne: #234B53;
$color-creole: #0D0202;
$color-half-baked: #89cbcd;
$color-mexican-red: #A52222;
$color-ming: #397E84;
$color-monarch: #f64548;
$color-valencia: #D73C3C;
$color-white: #ffffff;

$color-bg: $color-white;
$color-border: $color-blue-dianne;
$color-green: $color-half-baked;
$color-green-text: $color-ming;
$color-red: $color-monarch;
$color-red-dark: $color-mexican-red;
$color-text: $color-creole;
$color-text-dark: $color-blue-dianne;
$color-red-text: $color-valencia;


$content-min-width: 16em;
$content-mid-width: 48em;
$content-max-width: 63em;

$space: 1rem;
$size: $space;

$bp-xxs: 24em; // 384px
$bp-xs: 30em; // 480px
$bp-sm: 33.75em; // 540px
$bp-md: 48em; // 768px
$bp-lg: 62em; // 992px
$bp-xl: 75em; // 1200px