.hidden {
  display: none;
}

.hide {
  position: absolute;
  left: -9999em;
}

.list {
  display: block;
  margin: $space 0 $space;

  .item {
    display: block;
    margin: 0 0 $space/2;
  }
}

.offscreen {
  left: $space*9999;
  position: absolute;
}

.w100 {
  width: 100%;
}