.carousel {
  &.carousel-slider {
    min-height: $space*6;
  }

  .carousel-status {
    display: none !important;
  }

  .control-dots {
    bottom: $space/4 !important;
    margin: 0 !important;

    @include bp(md) {
      bottom: $space*1.5 !important;
    }

    .dot {
      height: $space/1.5 !important;
      opacity: 0.5 !important;
      width: $space/1.5 !important;

      &:hover {
        background: $color-red !important;
        opacity: 1 !important;
      }

      &.selected {
        opacity: 1 !important;

        &:hover {
          background: $color-white !important;
        }
      }
    }
  }

  .slide {
    background: none !important;
    padding-bottom: $space*2;

    .legend {
      border-radius: 0 !important;
      bottom: 0 !important;
      font-size: $size !important;
      line-height: 1.1;
      margin-left: -50% !important;
      opacity: 1 !important;
      padding: $space*0.5 $space/2 $space/1.5 !important;
      width: 100% !important;

      @include bp(md) {
        bottom: $space !important;
        font-size: $size !important;
        padding: $space !important;
      }

      .view--home & {
        padding-bottom: $space*1.5 !important;

        @include bp(md) {
          padding-bottom: $space*2 !important;
        }
      }

      a {
        color: white;

        &:focus, 
        &:hover {
          color: $color-green;
        }
      }
    }
  }

  .thumbs-wrapper {
    border-bottom: 1px solid $color-border;
    margin: $space/1.5 2px $space !important;
    padding-bottom: $space/2;
  }

  .thumb.selected,
  .thumb:hover {
    border-color: $color-red !important;
  }
}

.view--about,
.view--contact,
.view--events {
  .control-dots,
  .thumbs-wrapper {
    display: none !important;
   }
}