.logo {
  img {
    height: 8em;
    transition: opacity 0.6s;
    width: auto;

    @include bp(xs) {
        height: 9em;
    }

    @include bp(lg) {
        height: 11em;
    }
  }

  &-hover {
    display: block;
    opacity: 0;
    position: absolute;
    z-index: 6;
  }
    

  a:hover .logo-hover {
    opacity: 1;
  }
}