body {
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
  color: $color-green-text;

  &:focus, &:hover {
    color: $color-red-text;
  }
}

h1, h3, p {
  color: $color-text;
}

h1 {
  color: $color-red-dark;
  font-size: $size*3;
  margin: $space 0;
}

h2 {
  color: $color-green-text;
  font-size: $size*1.6;
  line-height: 1.2;
  margin: 0 0 $space;

  @include bp(lg) {
    max-width: 74%;
  }
}

h3 {
  color: $color-text-dark;
  font-size: $size*1.1;
  line-height: 1.3;

  @include bp(lg) {
    font-size: $size*1.3;
  }
}